import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useDeleteProgramMutation, useFetchProgramsQuery } from "../../store";
import { useCoach } from "../../useCoach";
import { useProgramService } from "../../services/program.service";
import DuplicateProgramModal from "../../components/modals/DuplicateProgramModal";
import { useState } from "react";

function Programs(){
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [deleteProgram, results] = useDeleteProgramMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const { duplicateProgram } = useProgramService();
  const handleDelete = (e,program)=>{
    e.preventDefault();
    deleteProgram(program);
  }
  const handleDuplicate = async (newProgramName) => {
    await duplicateProgram(selectedProgramId, newProgramName, refetch);
  };
  const handleDuplicateClick = (programId) => {
    setSelectedProgramId(programId);
    setIsModalOpen(true);
  };
  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (program) => program.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'Name',
      label: 'Name',
      render: (program) => program.name,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Type',
      label: 'Type',
      render: (program) => program.type,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Duplicate',
      label: '',
      render: (program) => <button
        onClick={() => handleDuplicateClick(program.id)}
        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
      > Duplicate Program
      </button>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (program) => <NavLink to={`/program/${program.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {program.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Delete',
      label: '',
      render: (program) => <NavLink to="/" onClick={(e)=>handleDelete(e,program.id)} className="text-indigo-600 hover:text-indigo-900">
        Delete<span className="sr-only">{program.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/program/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (program) => {
    return program.id;
  };

  const { data, error, isLoading, refetch} = useFetchProgramsQuery(coach.id);
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Programs" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    <DuplicateProgramModal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      onDuplicate={handleDuplicate}
    />
    </>
  );
}

export default Programs;