import { useLazyFetchWorkoutQuery, useCreateWorkoutExerciseMutation, useCreateSetObjectiveMutation, useCreateWorkoutMutation } from "../store";

export const useWorkoutService = () => {
  const [getWorkout] = useLazyFetchWorkoutQuery();
  const [createWorkout] = useCreateWorkoutMutation();
  const [createWorkoutExercise] = useCreateWorkoutExerciseMutation();
  const [createSetObjective] = useCreateSetObjectiveMutation();

  const handleDuplicate = async (workoutId, refetch) => {
    try {
      const { data, error } = await getWorkout(workoutId);
      if (error) {
        console.error('Error fetching duplicated workout:', error);
      } else {
        let workout = data[0];
        const {data:createdWorkoutArray} = await createWorkout({name:workout.name+"-Copy", coach: workout.coach});
        let createdWorkout = createdWorkoutArray[0];
        workout.workout_exercise.forEach(async(workout_exercise)=>{
          let {data:createdWorkoutExerciseArray} = await createWorkoutExercise({ workout: createdWorkout.id, exercise: workout_exercise.exercise.id, order: workout_exercise.order, note: workout_exercise.note });
          let createdWorkoutExercise = createdWorkoutExerciseArray[0];
          workout_exercise.set_objective.forEach((set)=>{
            createSetObjective({
              workout_exercise: createdWorkoutExercise.id,
              index: set.index,
              kg: set.kg,
              reps: set.reps,
              rpe: set.rpe,
              rir: set.rir,
              tut: set.tut,
              time: set.time,
              rest: set.rest,
              percentage_rm: set.percentage_rm,
              note: set.note
            });
          });
        });
        refetch();
      }
    } catch (error) {
      console.error('Error fetching duplicated workout:', error);
    }
  };

  return {
    handleDuplicate,
  };
};
