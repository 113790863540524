import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useDeleteFoodMutation, useFetchFoodsQuery } from "../../store";

function Foods(){
  const navigate = useNavigate();
  const [deleteFood, results] = useDeleteFoodMutation();
  
  const handleDelete = (e,food)=>{
    e.preventDefault();
    deleteFood(food);
  }
  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (program) => program.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'English Name',
      label: 'English Name',
      render: (food) => food.name_en,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Spanish Name',
      label: 'Spanish Name',
      render: (food) => food.name_es,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Type',
      label: 'Type',
      render: (food) => food.type,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Meal Preference 1',
      label: 'Meal Preference 1',
      render: (food) => food.meal_preference1,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Meal Preference 2',
      label: 'Meal Preference 2',
      render: (food) => food.meal_preference2,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Serving Size',
      label: 'Serving Size',
      render: (food) => food.serving_size,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Calories',
      label: 'Calories',
      render: (food) => food.calories,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Carbs',
      label: 'Carbs',
      render: (food) => food.carbs,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Protein',
      label: 'Protein',
      render: (food) => food.protein,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Fat',
      label: 'Fat',
      render: (food) => food.fat,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (food) => <NavLink to={`/food/${food.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {food.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Delete',
      label: '',
      render: (food) => <NavLink to="/" onClick={(e)=>handleDelete(e,food.id)} className="text-indigo-600 hover:text-indigo-900">
        Delete<span className="sr-only">{food.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/food/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (food) => {
    return food.id;
  };

  const { data, error, isLoading, refetch} = useFetchFoodsQuery();
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Foods" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    </>
  );
}

export default Foods;