import { useLazyFetchWorkoutQuery, useLazyFetchProgramQuery, useCreateWorkoutExerciseMutation, useCreateSetObjectiveMutation, useCreateWorkoutMutation, useCreateProgramMutation } from "../store";

export const useProgramService = () => {
  const [getWorkout] = useLazyFetchWorkoutQuery();
  const [getProgram] = useLazyFetchProgramQuery();
  const [createWorkout] = useCreateWorkoutMutation();
  const [createWorkoutExercise] = useCreateWorkoutExerciseMutation();
  const [createSetObjective] = useCreateSetObjectiveMutation();
  const [createProgram] = useCreateProgramMutation();

  const duplicateProgram = async (programId, nameNewProgram, refetch) => {
    try {
      
      //1. Get Program Data
      const { data: program, isLoading: isProgramLoading, error: programError } = await getProgram(programId);
      //2. Copy Program Data
      const {data:createdProgramArray} = await createProgram({name: program[0].name+" "+nameNewProgram, type:program[0].type, coach: program[0].coach });
      let createdProgram = createdProgramArray[0];

      // **********
      //Determinar si debemos skippear la segunda query
      // const fetchedProgramId = program?.[0]?.id;
      // const shouldSkip = !fetchedProgramId;
      //Hook para obtener los últimos sets ejecutados
      // const { data: workout, error, isLoading } = await getWorkout(shouldSkip ? undefined : 1, {
      //   skip: shouldSkip,
      // });
      // **********

      //3. Get each workout assigned
      const workouts = program[0].workout;

      for (const workoutInProgram of workouts) {
        const { data, error, isLoading } = await getWorkout(workoutInProgram.id);
        if (error) {
          console.error('Error fetching duplicated workout:', error);
        } else if (isLoading) {
          console.error('Loading...', isLoading);
        }else {
          //4. Copy each workout
          let workout = data[0];
          //5. Assign each new workout to the program
          const {data:createdWorkoutArray} = await createWorkout({name:nameNewProgram+" "+workout.name, coach: workout.coach, order:workout.order, program:createdProgram.id});
          let createdWorkout = createdWorkoutArray[0];
          workout.workout_exercise.forEach(async(workout_exercise)=>{
            let {data:createdWorkoutExerciseArray} = await createWorkoutExercise({ workout: createdWorkout.id, exercise: workout_exercise.exercise.id, order: workout_exercise.order, note: workout_exercise.note });
            let createdWorkoutExercise = createdWorkoutExerciseArray[0];
            workout_exercise.set_objective.forEach((set)=>{
              createSetObjective({
                workout_exercise: createdWorkoutExercise.id,
                index: set.index,
                kg: set.kg,
                reps: set.reps,
                rpe: set.rpe,
                rir: set.rir,
                tut: set.tut,
                time: set.time,
                rest: set.rest,
                percentage_rm: set.percentage_rm,
                note: set.note
              });
            });
          });
        }
      }
      refetch();
    } catch (error) {
      console.error('Error fetching duplicated workout:', error);
    }
  };

  return {
    duplicateProgram,
  };
};
