import React from 'react';

function UnitInput({ id, label, unit, value, disabled = false }) {

  return (
    <div className="flex-1">
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">{label}</label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          name={id}
          id={id}
          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="0.00"
          aria-describedby={`${id}-unit`}
          defaultValue={value}
          {...(disabled ? { disabled: true } : {})}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id={`${id}-unit`}>{unit}</span>
        </div>
      </div>
    </div>
  );
}

export default UnitInput;
